import { Partner } from "@/types";
import { MutationTree } from "vuex";
import { PartnerState } from "./types";


export enum PARTNER_MUTATIONS{
  SET_PARTNERS = 'SET_PARTNERS'
}

export const mutations: MutationTree<PartnerState> = {
  [PARTNER_MUTATIONS.SET_PARTNERS](state, payload: Partner[]){
    state.partners = payload;
    state.loading = false;
  },
}