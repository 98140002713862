
import { Module } from "vuex";
import { RootState } from "../types";
import { PartnerState } from "./types";
import { actions } from "./actions";
import { getters } from './getters';
import { mutations } from "./mutations";

const state: PartnerState = {
  loading: false,
  partners: []
};

export const partner: Module<PartnerState, RootState> = {
  state,
  actions,
  getters,
  mutations
}