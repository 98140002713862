import { auth, firestore } from "@/firebase";
import { GetPartners } from "@/services/FactCheckService";
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { PARTNER_MUTATIONS } from "./mutations";

import { PartnerState } from "./types";


export enum PARTNER_ACTIONS {
  LOAD_PARTNERS = 'LOAD_PARTNERS',
  GET_PARTNER_NAME = 'GET_PARTNER_NAME'
}

export const actions: ActionTree<PartnerState, RootState> = {
  async [PARTNER_ACTIONS.LOAD_PARTNERS]({ commit }) {
    const partners = await GetPartners()
    commit(PARTNER_MUTATIONS.SET_PARTNERS, partners)
  },
}