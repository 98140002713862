import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
    {
        path: '/integrations/efcsn',
        component: () => import('@/views/Integrations/EFCSN.vue'),
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        }
    },
    {
        path: '/integrations/google-factcheck-tools',
        component: () => import('@/views/Integrations/GoogleFactcheckTools.vue'),
        meta: {
            requiresAuth: true,
            requiresAdmin: false,
        }
    },
]

export default routes;