import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: '/stats',
    component: () => import('@/views/Stats/Index.vue'),
    redirect: '/stats/interactions',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'interactions',
        component: () => import('@/views/Stats/Interactions.vue')
      },
      {
        path: 'social',
        component: () => import('@/views/Stats/Social.vue')
      },
      {
        path: 'claims',
        component: () => import('@/views/Stats/Claims.vue')
      },
      {
        path: 'visitors',
        component: () => import('@/views/Stats/Visitors.vue')
      },
      {
        path: 'reports',
        component: () => import('@/views/Stats/Reports.vue')
      },
      {
        path: 'requests',
        component: () => import('@/views/Stats/Requests.vue')
      }
    ]
  },
]

export default routes;