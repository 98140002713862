import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
    {
        path: '/rss-feeds',
        component: () => import('@/views/RssFeeds/Index.vue'),
    },
]

export default routes;

