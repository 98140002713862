import { HomeScreenStats } from "@/types";

import api from '@/plugins/axios';

export const GetHomeScreenStats = async (articlesWithoutFactcheckDaysAgo: number): Promise<HomeScreenStats> => {
  const { data } = await api.get<HomeScreenStats>('/analytics/stats', {
    params: {
      articlesWithoutFactcheckDaysAgo
    }
  });

  return data;
}

export const GetFactcheckCountsPerCategory = async () => {
  const { data } = await api.get('/analytics/claims-per-category');
  return data;
}

export const GetFactcheckCountsPerClaimant = async () => {
  interface response {
    claimant: string,
    count: string,
  }
  const { data } = await api.get<response[]>('/analytics/claims-per-claimant');

  return data.map(e => {
    return {
      claimant: e.claimant,
      count: parseInt(e.count)
    }
  })
}

export const GetVisitorCountFromFromRange = async (from: Date, to: Date): Promise<any[]> => {
  const { data } = await api.get<any[]>(`/analytics/visitors-per-day?from=${from.toISOString()}&to=${to.toISOString()}`);
  return data;
}

export const GetFactcheckStats = async (id: string) => {
  const { data } = await api.get(`/factchecks/${id}/stats`);

  return data;
}

export const ListInteractions = async (from: Date, to: Date, type?: number) => {
  const { data } = await api.get(`/analytics/interactions?from=${from.toISOString()}&to=${to.toISOString()}`);

  return data;
}

export const ListAggregatedInteractions = async (from: Date, to: Date,) => {
  const { data } = await api.get(`/analytics/interactions?from=${from.toISOString()}&to=${to.toISOString()}&aggregate=true`);

  return data;
}

export const ListInteractionsPerPartner = async (from: Date, to: Date) => {
  const { data } = await api.get(`/analytics/interactions-per-partner?from=${from.toISOString()}&to=${to.toISOString()}`);

  return data;
}



export const CountTypesOfInteractionPerHour = async (from: Date, to: Date) => {
  const { data } = await api.get(`/analytics/interaction-types-per-hour?from=${from.toISOString()}&to=${to.toISOString()}`);

  return data;
}

export const CountVisitorsPerMonth = async () => {
  const { data } = await api.get(`/analytics/visitors-per-month`);

  return data;
}

export const AverageClicksPerVisitor = async () => {
  const { data } = await api.get(`/analytics/average-interactions-per-user`);

  return data;
}

export const GetBounceRate = async (start: Date, end: Date) => {
  const { data } = await api.get(`/analytics/bounce-rate?from=${start.toISOString()}&to=${end.toISOString()}`);

  return data;

}

export const GetAverageSessionDuration = async (start: Date, end: Date) => {
  const { data } = await api.get(`/analytics/average-session-duration?from=${start.toISOString()}&to=${end.toISOString()}`);

  return data;
}

export const GetVisitorSources = async (start: Date, end: Date) => {
  const { data } = await api.get(`/analytics/visitor-sources?from=${start.toISOString()}&to=${end.toISOString()}`);

  return data;
}

export const GetVisitorReferrals = async (start: Date, end: Date) => {
  const { data } = await api.get(`/analytics/visitor-referrals?from=${start.toISOString()}&to=${end.toISOString()}`);

  return data;
}

export const GetUniqueNewVisitorsPerDay = async () => {
  const { data } = await api.get(`/analytics/unique-new-visitors-per-day`);
  return data;
}