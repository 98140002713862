import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> =  [
  {
    path: '/accounts',
    component: () => import('@/views/Accounts/Overview.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/accounts/:userId',
    component: () => import('@/views/Accounts/SingleUserOverview.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  }
]

export default routes;