
import { Component, Vue, Watch } from "vue-property-decorator";

import * as StatServices from "@/services/StatServices";
import * as FactcheckServices from '@/services/FactCheckService';

import { Factcheck, HomeScreenStats } from "@/types";
@Component({
  components: {
    FactCheckArticleCard: () =>
      import("@/components/cards/FactcheckArticleCard.vue"),
  },
})
export default class Home extends Vue {
  chartOptions = {
    chart: {
      id: 'vistors'
    },
    stroke: {
      curve: 'straight'
    },
    xaxis: {
      type: 'datetime',
    },
    dataLabels: {
      enabled: false
    },
  }

  series = [{
    name: 'Bezoekers',
    data: [] as any[]
  }]

  loading = {
    visitorsThisWeek: true,
    globalClickTroughRate: true,
    stats: true,
  };

  dateRangeSelector = {
    fromToggle: false,
    from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7).toISOString().substring(0, 10),
    toToggle: false,
    to: new Date().toISOString().substring(0, 10),
  }

  stats: HomeScreenStats = {
    visitorsToday: 0,
    articlesWithoutFactcheckCount: 0,
    totalFactcheckCount: 0,
    globalClickTroughRate: 0,
    mostPopularIdsToday: null,
  };

  mostPopularArticleToday = null as Factcheck[] | null;

  allInteractionsToday = [] as any[]

  async mounted(): Promise<void> {
    this.stats = await StatServices.GetHomeScreenStats(7);
    let startOfToday = new Date()
    startOfToday.setHours(0, 0, 0, 0)


    const interactions = await StatServices.ListInteractions(startOfToday, new Date())
    this.allInteractionsToday = Object.values(interactions).flatMap((arr) => arr);
    console.log(this.allInteractionsToday)
    this.loading.stats = false;



    if (this.stats.mostPopularIdsToday) {
      this.mostPopularArticleToday = [];
      for (let id of this.stats.mostPopularIdsToday) {
        this.mostPopularArticleToday?.push(await FactcheckServices.GetFactCheck(id) as Factcheck);
      }
    }
  }

  @Watch('dateRangeSelector.from', { immediate: true })
  @Watch('dateRangeSelector.to')
  async drawVisitorsPerDay() {
    this.loading.visitorsThisWeek = true;
    const visitorsPerDay = await StatServices.GetVisitorCountFromFromRange(new Date(this.dateRangeSelector.from), new Date(this.dateRangeSelector.to));

    this.chartOptions = {
      ...this.chartOptions,
      xaxis: {
        type: 'datetime',
      }
    }

    // console.log( visitorsPerDay.map(e => e.visitorCount))

    this.series = [
      {
        data: visitorsPerDay.map(e => {
          return [new Date(e.date).getTime(), parseInt(e.visitorCount, 10)]
        }),
        name: 'Unieke Bezoekers',
      },
      // { data: visitorsPerDay.map(e => e.ga_newUsers), name: 'Nieuwe Bezoekers (Google Analytics)' }
    ]

    console.log('series')
    console.log(this.series)

    this.loading.visitorsThisWeek = false;
  }

  radialChartOptions = {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2
          }
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -2,
            fontSize: '22px'
          }
        }
      }
    },
    grid: {
      padding: {
        top: -10
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91]
      },
    },
    labels: ['Average Results'],
  }

}
