import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: '/api-management',
    component: () => import('@/views/Api/Index.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/api-management/:apiKeyId',
    component: () => import('@/views/Api/Calls.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  }
]

export default routes;