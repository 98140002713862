import { auth, firestore } from "@/firebase";
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { USER_MUTATIONS } from "./mutations";

import { UserState } from "./types";

import * as UserServices from '@/services/UserServices'

export enum USER_ACTIONS {
  AUTHENTICATE_USER = "AUTHENTICATE_USER",
  SIGNOUT = "SIGNOUT"
}

export const actions: ActionTree<UserState, RootState> = {
  async [USER_ACTIONS.AUTHENTICATE_USER]({commit}){
    if(!auth().currentUser){
      commit(USER_MUTATIONS.SET_USER, {
        id: '',
      })
    } else {
      if(auth().currentUser?.providerId == 'anonymous'){
        commit(USER_MUTATIONS.SET_USER, {
          id: '',
        })
      } else{
        const user = await UserServices.GetCurrentUser(); 
        commit(USER_MUTATIONS.SET_USER, user);
      }
    }
  },
  [USER_ACTIONS.SIGNOUT]({commit}){
    commit
    throw new Error();
  }
}