import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> =  [
  {
    path: '/factchecks',
    component: () => import('@/views/FactChecks/Index.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/factchecks/deleted',
    component: () => import('@/views/FactChecks/Deleted.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/factchecks/new',
    component: () => import('@/views/FactChecks/New.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/factchecks/:id/claimreviews/new',
    component: () => import('@/views/FactChecks/AddClaimReview.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/factchecks/:id/overview',
    component: () => import('@/views/FactChecks/SingleArticleOverview.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

export default routes;