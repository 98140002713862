import Vue from 'vue'
import vuetify from './vuetify'
import 'tiptap-vuetify/dist/main.css'
//@ts-ignore
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'


Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})


export default TiptapVuetifyPlugin;