import { User } from "@/store/user/types";
import api from '@/plugins/axios';

export const CreateUser = async (name: string, email: string, password: string, admin: boolean): Promise<User> => {
  const { data } = await api.post<User>('/users', {
    name,
    email,
    password,
    isAdmin: admin
  })

  return data;
}

export const RevokeSessions = async (userId: string): Promise<void> => {
  await api.post(`/users/${userId}/revoke-sessions`);
  return;
}

export const GetUser = async (userId: string): Promise<User> => {
  const { data } = await api.get<User>(`/users/${userId}`);

  return data;
}

export const UpdateUser = async (user: Partial<User>): Promise<User> => {
  const { data } = await api.patch<User>(`/users/${user.id}`, user);
  return data;
}

export const DeleteUser = async (userId: string): Promise<void> => {
  await api.delete(`/users/${userId}`);
  return;
}

export const GetCurrentUser = async (): Promise<User> => {
  const { data } = await api.get<User>('/users/whoami');

  return data;
}

export const ListUsers = async (): Promise<User[]> => {
  const { data } = await api.get<User[]>('/users');
  return data;
}