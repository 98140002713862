import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> =  [
 {
   path: '/scraper/overview',
   component: () => import('@/views/Scraper/Overview.vue'),
   meta: {
    requiresAuth: true
  }
 },
 {
   path: '/scraper/results',
   component: () => import('@/views/Scraper/Results.vue'),
   meta: {
    requiresAuth: true
  }
 }
]

export default routes;