import { GetterTree } from "vuex";
import { RootState } from "../types";
import { PartnerState } from "./types";

export enum PARTNER_GETTERS {
  GET_PARTNER = 'GET_PARTNER'
}

export const getters: GetterTree<PartnerState, RootState> = {
  [PARTNER_GETTERS.GET_PARTNER]: (s) => ((id: string) => s.partners.find((x) => x.id === id) || "Onbekende partner"),
}