import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/performance'
import 'firebase/functions'

const config = {
  apiKey: "AIzaSyBKlIFrO6JLw8Thuz2Ojy9mlwVgNKig7UU",
  authDomain: "decheckers.firebaseapp.com",
  projectId: "decheckers",
  storageBucket: "decheckers.appspot.com",
  messagingSenderId: "87127659636",
  appId: "1:87127659636:web:b60ae6bc47667a95ecad21",
  measurementId: "G-339EDJYXDD"
};


firebase.default.initializeApp(config);

firebase.default.analytics();
firebase.default.performance();

const firestore = firebase.default.firestore
const auth = firebase.default.auth
const storage = firebase.default.storage
const app = firebase.default.app;
const functions = firebase.default.app().functions('europe-west1')

export {
  firestore,
  storage,
  auth,
  app,
  functions
}