import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> =  [
 {
   path: '/settings',
   component: () => import('@/views/Settings/Index.vue'),
   meta: {
    requiresAuth: true
  }
 },
]

export default routes;