import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> =  [
  {
    path: '/claim-categories',
    component: () => import('@/views/ClaimCategories/Index.vue'),
    redirect: '/claim-categories/list',
    children: [
      {
        path: 'list',
        name: 'ClaimCategoriesList',
        component: () => import('@/views/ClaimCategories/List.vue'),
      },
      {
        path: 'new',
        name: 'ClaimCategoriesCreate',
        component: () => import('@/views/ClaimCategories/New.vue'),
      },
      {
        path: 'move',
        component: () => import('@/views/ClaimCategories/Move.vue'),
      },
    ],
    meta: {
      requiresAuth: true,
    }
  },
]

export default routes;