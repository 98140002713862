
import { Vue, Component } from "vue-property-decorator";
import { ROOT_MUTATIONS } from "./store/mutations";
import { Snackbar } from "./store/types";
import { USER_ACTIONS } from "./store/user/actions";

import { PARTNER_ACTIONS } from "./store/partner/actions";
import { auth } from "./firebase";

@Component({
  components: {
    Navigation: () => import("@/components/layout/Navigation.vue"),
  },
})

export default class App extends Vue {
  loading = true;

  multiFactorEnrollmentDialog = true;

  get backgroundColor(): string {

    return this.$vuetify.theme.dark ? '#1f1f1f' : '#f6f7f9'
  }

  get snackbar(): Snackbar {
    return this.$store.state.snackbar;
  }

  set snackbar(value: Snackbar) {
    this.$store.commit(ROOT_MUTATIONS.TOGGLE_SNACKBAR, value);
  }

  async created() {
    try {
      await this.$store.dispatch(USER_ACTIONS.AUTHENTICATE_USER);

      setTimeout(() => {
        this.loading = false;
      }, 1000);

      await this.$store.dispatch(PARTNER_ACTIONS.LOAD_PARTNERS);

      const currentUser = auth().currentUser;

      if (currentUser) {
        currentUser.multiFactor.getSession().then((multiFactorSession) => {
          this.multiFactorEnrollmentDialog = true;
        })
      }

    } catch (error) {
      this.$router.push('/login')
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }

  }

  get authenticated(): boolean {
    const multiFactor = auth().currentUser?.multiFactor;

    const storeAuth = this.$store.state.user.authenticated;

    if (multiFactor) {
      return storeAuth && multiFactor.enrolledFactors.length > 0;
    }

    return false;

  }
}
