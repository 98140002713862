import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
    {
        path: '/ai',
        component: () => import('@/views/Ai/Index.vue'),
        meta: {
            requiresAuth: true,
        }
    },
]

export default routes;