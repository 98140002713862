import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: '/workshop-forms',
    component: () => import('@/views/WorkshopForms/Index.vue'),
    redirect: '/workshop-forms/list',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'list',
        component: () => import('@/views/WorkshopForms/List.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'stats',
        component: () => import('@/views/WorkshopForms/Stats.vue'),
        meta: {
          requiresAuth: true,
        }
      }
    ]
  },
  {
    path: '/workshop-forms/:id',
    component: () => import('@/views/WorkshopForms/Details.vue'),
    meta: {
      requiresAuth: true,
    }
  }
]

export default routes;