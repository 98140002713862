import { Claim, ClaimCategory, ClaimReview, Factcheck, FactcheckFilter, FactcheckIntegrationPublicationType, Partner } from "@/types"

import api from '@/plugins/axios';

export const GetPartners = async (includeDisabled = false): Promise<Partner[]> => {
  if (!includeDisabled) {
    const { data } = await api.get<Partner[]>('/partners?includeDisabled=false');
    return data;
  } else {
    const { data } = await api.get<Partner[]>('/partners?includeDisabled=true');
    return data;
  }
}

export const PublishClaimReview = async (id: string): Promise<void> => {
  await api.post(`/factchecks/${id}/claimreviews`);
}

export const CreatePublication = async (factcheckId: string, type: FactcheckIntegrationPublicationType): Promise<any> => {
  const { data } = await api.post(`/factchecks/${factcheckId}/integrations/publications`, { integration: type });
  return data;
}

export const GetPublications = async (factcheckId: string): Promise<FactcheckIntegrationPublicationType[]> => {
  const { data } = await api.get(`/factchecks/${factcheckId}/integrations/publications`);
  return data.map((e: any) => e.integrationType);
}

export const ListAllPublications = async (): Promise<Array<{ integrationType: FactcheckIntegrationPublicationType, FactcheckId: string, externalId: string }>> => {
  const { data } = await api.get(`/factchecks/integration-publications`);
  return data;
}

export const UpdatePartner = async (partner: Partial<Partner>): Promise<Partner> => {
  const { data } = await api.patch<Partner>(`/partners/${partner.id}`, partner);

  return data;
}

export const GetFactCheck = async (id: string): Promise<Factcheck | null> => {
  const { data } = await api.get<Factcheck>(`/factchecks/${id}`);

  return data;
}

export const UpdateFactcheck = async (factcheck: Partial<Factcheck>): Promise<Factcheck> => {
  const { data } = await api.patch(`/factchecks/${factcheck.id}`, {
    ...factcheck
  })

  return data;
}

export const UpdateClaimReview = async (claimreview: Partial<ClaimReview>): Promise<ClaimReview> => {
  const { data } = await api.patch<ClaimReview>(`/factchecks/claimreviews/${claimreview.id}`, claimreview);

  return data;
}

export const UpdateClaim = async (claim: Partial<Claim>): Promise<Claim> => {
  const { data } = await api.patch<Claim>(`/factchecks/claims/${claim.id}`, claim);
  return data;
}

export const SetClaimCategories = async (claimId: string, categories: string[]): Promise<void> => {
  await api.put(`/factchecks/claims/${claimId}/categories`, categories);
}

export const ListClaims = async (): Promise<{ claim: string, id: number }[]> => {
  const { data } = await api.get<Array<{ claim: string, id: number }>>('/factchecks/claims/list');

  return data;
}

export const RestoreFactcheck = async (factcheckId: string): Promise<void> => {
  await api.post(`/factchecks/${factcheckId}/restore`);
}

export const CreateClaim = async (claim: Partial<Claim>): Promise<Claim> => {
  const { data } = await api.post<Claim>(`/factchecks/claims`, claim)

  return data;
}

export const CreateClaimReview = async (claimId: string, claimReview: Partial<ClaimReview>, scrapedArticle: string | null, imageUrl: string): Promise<ClaimReview> => {
  if (scrapedArticle) {
    const { data } = await api.post<ClaimReview>(`/factchecks/claims/${claimId}/claimreviews?scrapedArticle=${scrapedArticle}`, {
      ...claimReview,
      imageUrl,
    })

    return data;
  }

  const { data } = await api.post<ClaimReview>(`/factchecks/claims/${claimId}/claimreviews`, {
    ...claimReview,
    imageUrl,
  })

  return data;

}

export const ExportFactchecks = async () => {
  const { data } = await api.get('/factchecks/export', {
    responseType: 'blob'
  });
  return data;
}

export const UpdateSEO = async (factcheckId: string, seo: { description: string, keywords: string }): Promise<void> => {
  await api.put(`/factchecks/${factcheckId}/seo`, seo);
}

export const CreateFactcheck = async (article: Partial<Factcheck>): Promise<Factcheck> => {
  const { data } = await api.post<Factcheck>('/factchecks', {
    isPublished: article.isPublished,
    title: article.title,
    ClaimId: article.ClaimId,
    description: article.description,
    sortDate: article.sortDate,
    childFriendly: article.childFriendly,
  })

  return data
}

export const GetDeletedFactchecks = async (): Promise<Factcheck[]> => {
  const { data } = await api.get<Factcheck[]>('/factchecks/deleted');
  return data;
}


export const SetClaim = async (factcheckId: string, claimReviewId: string, claim: Partial<Claim>): Promise<Claim> => {
  const { data } = await api.put<Claim>(`/factchecks/${factcheckId}/claimreviews/${claimReviewId}/claim`, claim);

  return data;
}

export const GetAllFactChecks = async (filter: FactcheckFilter, limit = 20, offset = 0): Promise<Factcheck[]> => {
  const query: string[] = [];

  if (filter.dateRange) {
    query.push(`start=${filter.dateRange[0].toISOString()}`);
    query.push(`end=${filter.dateRange[1].toISOString()}`)
  }

  if (filter.category) query.push(`category=${filter.category}`)
  if (filter.numericRating) query.push(`numericRating=${filter.numericRating}`);
  if (filter.query && filter.query.length > 0) query.push(`query=${filter.query}`)
  if (filter.partners && filter.partners.length > 0) query.push(`partner=${filter.partners.join(',')}`)

  query.push(`limit=${limit}`)
  query.push(`offset=${offset}`)


  query.push(`includeUnpublished=true`)
  query.push(`cache-bypass=${Date.now()}`)
  const { data } = await api.get<Factcheck[]>(`/factchecks?${query.join('&')}`, {});
  return data;
}

export const GetFactCheckArticlesFromUser = async (userId: string): Promise<Factcheck[]> => {
  return [];
}

export const CreateConcept = async (concept: any): Promise<string> => {
  const { data } = await api.post('/concepts', concept);

  return data.id;
}

export const SaveConcept = async (id: string, data: any): Promise<void> => {
  await api.patch(`/concepts/${id}`, data);
}

export const GetConcept = async (conceptId: string) => {
  const { data } = await api.get(`/concepts/${conceptId}`);
  return data;
}

export const GetClaimants = async (): Promise<string[]> => {
  const { data } = await api.get<string[]>('/factchecks/claims/claimants');

  return data;
}

export const GetConcepts = async (): Promise<any[]> => {
  const { data } = await api.get(`/concepts/`);
  return data;
}

export const DeleteConcept = async (conceptId: string): Promise<void> => {
  await api.delete(`/concepts/${conceptId}`);
}

export const DeleteFactcheck = async (factcheckId: string): Promise<void> => {
  await api.delete<void>(`/factchecks/${factcheckId}`);
  return;
}