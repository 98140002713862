import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import { required, email, max, size, image, mimes, ext } from 'vee-validate/dist/rules';
import nl from 'vee-validate/dist/locale/nl.json'


localize('nl', nl);
extend('image', image);
extend('size', size);
extend('max', max);
extend("required", required);
extend("email", email);
extend('mimes', mimes)

extend("password" , {
  validate: (value: string) => value.length >= 6,
  message: "minimum 6 karakters"
})

extend("positive" , {
    validate: (value: number) => value > -1,
    message: "Number must be positive"
});

extend('url' , {
  validate: (value: string) => {
    const forBiddenChars = ['&'];
    return !new RegExp(forBiddenChars.join("|")).test(value);
  }
});



export {
  ValidationProvider,
  ValidationObserver
}