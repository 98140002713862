import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import { auth } from '@/firebase';

Vue.use(VueRouter)

import FactCheckRoutes from './modules/factchecks';
import AccountRoutes from './modules/accounts';
import ScraperRoutes from './modules/scraper';
import StatsRoutes from './modules/stats';
import AboutRoutes from './modules/about';
import SettingsRoutes from './modules/settings';
import RequestsRoutes from './modules/requests';
import GoogleClaimsRoutes from './modules/googleClaims';
import ConceptRoutes from './modules/concepts';
import ClaimCategoryRoutes from './modules/claimCategories';
import NewsletterRoutes from './modules/newsletter';
import SeoRoutes from './modules/seo';
import ApiRoutes from './modules/api';
import PartnerRoutes from './modules/partner';
import ContentRoutes from './modules/content';
import AiRoutes from './modules/ai';
import TwitterRoutes from './modules/twitter';
import RssRoutes from './modules/rss';
import IntegrationRoutes from './modules/integrations';
import WorkskopFormsRoutes from './modules/workshopForms';
import BlogRoutes from './modules/blogs';
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  ...FactCheckRoutes,
  ...AccountRoutes,
  ...ScraperRoutes,
  ...StatsRoutes,
  ...AboutRoutes,
  ...SettingsRoutes,
  ...RequestsRoutes,
  ...GoogleClaimsRoutes,
  ...ConceptRoutes,
  ...ClaimCategoryRoutes,
  ...SeoRoutes,
  ...ApiRoutes,
  ...PartnerRoutes,
  ...ContentRoutes,
  ...AiRoutes,
  ...TwitterRoutes,
  ...RssRoutes,
  ...IntegrationRoutes,
  ...WorkskopFormsRoutes,
  ...BlogRoutes,
  NewsletterRoutes,
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/signature',
    name: 'Signature',
    component: () => import('@/views/Signature.vue')
  },
  {
    path: '/error',
    component: () => import('@/views/Error.vue')
  },
  {
    path: '*',
    redirect: '/error?code:404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async (to, from, next) => {
  const currentUser = auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    // If the route requires authentication and there is no current user, redirect to login
    next('/login');
  } else if (requiresAuth) {
    const multiFactors = currentUser?.multiFactor.enrolledFactors;
    if (multiFactors && multiFactors.length > 0) {
      // If multiFactors exist and there are enrolled factors, proceed
      next();
    } else {
      // If multiFactors do not exist or there are no enrolled factors, redirect to login
      next('/login');
    }
  } else {
    // If the route does not require authentication, proceed
    next();
  }
});

export default router
