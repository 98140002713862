import { auth } from '@/firebase';
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "X-Authentication-ID": null,
  },
})

instance.interceptors.request.use(async config => {
  config.headers = {
    "X-Authentication-ID": await auth().currentUser?.getIdToken()
  }
  return config;
}, error => {
  return Promise.reject(error);
})


export default instance;