import { RouteConfig } from 'vue-router'

const routes: RouteConfig = {
  path: '/newsletter',
  component: () => import('@/views/NewsLetter/Index.vue'),
  meta: {
    requiresAuth: true,
    requiresAdmin: false,
  }
}


export default routes;