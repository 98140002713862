import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
    {
        path: '/blogs',
        component: () => import('@/views/Blogs/Index.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/blogs/:id',
        component: () => import('@/views/Blogs/Content.vue'),
        meta: {
            requiresAuth: true,
        }
    }
]

export default routes;