import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> =  [
  {
    path: '/concepts',
    component: () => import('@/views/Concepts/Index.vue'),
    meta: {
      requiresAuth: true,
    }
  },
]

export default routes;